@tailwind base;
@tailwind components;
@tailwind utilities;

*,
::after,
::before {
  box-sizing: border-box;
}
/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600&family=Montserrat&display=swap');

html {
  font-size: 100%;
} /*16px*/

svg {
  display: inline;
}
:root {
  /* colors */
  --primary-100: #e2e0ff;
  --primary-200: #c1beff;
  --primary-300: #a29dff;
  --primary-400: #837dff;
  --primary-500: #645cff;
  --primary-600: #504acc;
  --primary-700: #3c3799;
  --primary-800: #282566;
  --primary-900: #141233;

  --nav-links-text-color: #2f130a;

  /* grey */
  --grey-50: #f8fafc;
  --grey-100: #f1f5f9;
  --grey-200: #e2e8f0;
  --grey-300: #cbd5e1;
  --grey-400: #94a3b8;
  --grey-500: #64748b;
  --grey-600: #475569;
  --grey-700: #334155;
  --grey-800: #1e293b;
  --grey-900: #0f172a;
  /* rest of the colors */
  --black: #222;
  --white: #fff;
  --red-light: #f8d7da;
  --red-dark: #842029;
  --green-light: #d1e7dd;
  --green-dark: #0f5132;

  /* fonts  */
  --smallText: 0.7em;
  --mediumText: 1rem;

  /* rest of the vars */
  --backgroundColor: #fff;
  --textColor: var(--grey-900);
  --borderRadius: 0.25rem;
  --letterSpacing: 1px;
  --transition: 0.3s ease-in-out all;
  --max-width: 1120px;
  --fixed-width: 500px;
  --fluid-width: 90vw;

  /* box shadow*/
  --shadow-1: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --shadow-2: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --shadow-3: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --shadow-4: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --shadow-5: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  --shadow-6: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

body {
  background: var(--backgroundColor);
  font-weight: 400;
  color: var(--textColor);
  margin: 0;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  margin-bottom: 0.25rem;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: var(--letterSpacing);
}

h1 {
  margin-top: 0;
  font-size: 3.052rem;
}

h2 {
  font-size: 2.441rem;
}

h3 {
  font-size: 1.953rem;
}

h4 {
  font-size: 1.563rem;
}

h5 {
  font-size: 1.25rem;
}

small,
.text-small {
  font-size: var(--smallText);
}

a {
  text-decoration: none;
}
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.img {
  width: 100%;
  display: block;
  object-fit: cover;
}

.page-container {
  padding: 0.5rem;
  margin: 0 auto;
  text-align: center;
  /* display: flex; */
}

.page-container .filtros {
  margin-top: 0.75rem;
}

/* buttons */
.btn {
  cursor: pointer;
  border: transparent;
  padding: 0.25rem 0.75rem;
  box-shadow: var(--shadow-2);
  transition: var(--transition);
  text-transform: uppercase;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  font-weight: bold;
}

.btn svg {
  margin-right: 0.25rem;
}

.btn:hover {
  box-shadow: var(--shadow-4);
}
.btn-pill {
  border-radius: 9999px !important;
}
.btn:disabled {
  cursor: not-allowed;
}

/* alerts */
.alert {
  padding: 0.375rem 0.75rem;
  margin: 0.75rem auto;
  border-color: transparent;
  border-radius: var(--borderRadius);
  width: var(--fluid-width);
  max-width: var(--fixed-width);
  text-align: center;
  text-transform: capitalize;
}

.alert-danger {
  color: var(--red-dark);
  background: var(--red-light);
}
.alert-success {
  color: var(--green-dark);
  background: var(--green-light);
}

/* form */

.form {
  width: var(--fluid-width);
  max-width: var(--fixed-width);
  background: rgb(243 244 246);
  /* border-radius: var(--borderRadius); */
  box-shadow: var(--shadow-2);
  padding: 2rem 2.5rem;
  margin: 1rem auto;
  position: relative;
}
.form-label {
  display: block;
  font-size: var(--mediumText);
  margin-bottom: 0.5rem;
  text-transform: capitalize;
  letter-spacing: var(--letterSpacing);
}
.form-input,
.form-textarea {
  width: 100%;
  padding: 0.375rem 0.75rem;
  border-radius: var(--borderRadius);
  background: var(--backgroundColor);
  border: 1px solid var(--grey-200);
}

.form-row {
  margin-bottom: 1rem;
}

.form-textarea {
  height: 7rem;
}
::placeholder {
  font-family: inherit;
  color: var(--grey-400);
}
.form-alert {
  color: var(--red-dark);
  letter-spacing: var(--letterSpacing);
  text-transform: capitalize;
}
/* alert */

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.loading {
  width: 6rem;
  height: 6rem;
  border: 5px solid var(--grey-400);
  border-radius: 50%;
  border-top-color: var(--primary-500);
  animation: spinner 0.6s linear infinite;
  margin: 0 auto;
}
.loading {
  margin: 3rem auto;
}
/* title */

.title {
  text-align: center;
}

.title-underline {
  background: var(--primary-500);
  width: 7rem;
  height: 0.25rem;
  margin: 0 auto;
  margin-top: -1rem;
}
.page {
  min-height: calc(100vh - 6rem);
  width: var(--fluid-width);
  max-width: var(--max-width);
  margin: 0 auto;
  padding-top: 3rem;
}

.page-center {
  min-height: 100vh;
  display: grid;
  place-items: center;
}
.form-loading::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--borderRadius);
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  cursor: not-allowed;
}

.page-title {
  text-transform: uppercase;
  font-weight: bold;
}

/*
=============== 
Navbar
===============
*/

.nav-header {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  color: #fff;
}
.nav-toggle {
  font-size: 1.5rem;
  color: #fff;
  background: transparent;
  border-color: transparent;
  transition: var(--transition);
  cursor: pointer;
  margin: 0.5rem;
}
.nav-toggle.active {
  color: #837dff;
  transform: rotate(90deg);
}
.logo {
  height: 2.75rem;
  margin: auto 1rem;
}

.links a {
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: var(--spacing);
  display: block;
  padding: 0.5rem 1rem;
  font-weight: bold;
  transition: var(--transition);
}
.links a:hover {
  color: var(--clr-primary-5);
  padding-left: 1.5rem;
}
.links-container {
  height: 0;
  overflow: hidden;
  transition: var(--transition);
}
@media screen and (min-width: 800px) {
  .nav-center {
    max-width: 1170px;
    display: flex;
    align-items: center;
    padding: 0;
    height: 3rem;
  }
  .nav-header {
    padding: 0;
  }
  .nav-toggle {
    display: none;
  }
  .links-container {
    height: auto !important;
  }
  .links {
    display: flex;
    height: 3rem;
    transition: var(--transition);
  }
  .links li {
    margin-top: 0.75rem;
    margin-left: 0.25rem;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .links li:hover {
    cursor: pointer;
  }
  .links a {
    padding: 0;
    margin: 0.5rem 0.5rem;
  }
  .links a:hover {
    padding: 0;
    background: transparent;
    color: var(--clr-primary-5);
  }
}

/* NOVA LISTA */
@media screen and (min-width: 800px) {
  .lista {
    flex-direction: row !important;
    flex-wrap: wrap !important;
  }
  .lista-item {
    width: 25rem !important;
  }
}

.lista {
  display: flex;
  flex-direction: column;
}
.lista-item {
  padding: 0.5rem;
  margin: 0.5rem;
  border-radius: 0.5rem;
  /* flex: 1; */
  transition: var(--transition);
}

.lista-item .nome {
  text-align: left;
  font-size: 1.25rem;
  text-transform: capitalize;
}

.lista-item .nome.small {
  font-size: 1rem;
}

.lista-item .data {
  white-space: nowrap;
  text-align: left;
}

.btn-excluir {
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  color: #fff;
  background-color: rgb(153 27 27);
  font-size: small;
  transition: var(--transition);
}

.btn-excluir:hover {
  background-color: rgb(239 68 68);
}

/* LISTA */
.list-container {
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 0.75rem;
  box-shadow: var(--shadow-3);
  max-width: 40rem;
  max-height: 32rem;
  margin-top: 1rem;
}

.list-item {
  border-radius: 0.25rem;
  text-align: left;
  padding: 0.5rem;
  box-shadow: var(--shadow-3);
  transition: var(--transition);
  margin: 0.5rem;
  display: block !important;
}

.list-item:hover {
  box-shadow: var(--shadow-4);
}

.list-item-title {
  font-weight: bold;
  text-transform: uppercase;
}

/* SEARCH */
.search-filter {
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.search-filter input {
  background: inherit;
  margin-left: 0.25rem;
  padding-left: 0.25rem;
}

/* TABS */
.tabs-container {
  margin-top: 1rem;
  padding: 1rem;
}
.tab-item {
  margin-bottom: 0.25rem;
  margin-right: 1rem;
  text-transform: uppercase;
  transition: var(--transition);
  border-bottom: 3px solid rgba(24, 24, 24, 0.1);
  display: inline-block;
}

.tab-item.active {
  border-bottom: 3px solid rgb(56 189 248);
  /* transform: translateY(-0.25rem); */
}

.tab-item:hover {
  cursor: pointer;
}

.tab-content {
  margin-top: 1.25rem;
}
